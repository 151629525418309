<template>

  <el-form ref="dataForm">
    <el-form-item>

      <el-tabs v-model="currentCategory" type="border-card">

        <el-tab-pane
            v-for="(categoryItem, index) in categoryDataForm"
            :key="index"
            :label="categoryItem.description"
            :name="categoryItem.id">

          <el-row >
            <el-col style="margin-bottom: 10px; " v-for="(item, index) in categoryItem.ips" :key="index" :span="4">
              <el-tag style="cursor: pointer;" :type="item.select ? 'success' : 'info' " @click="ipClick(item)">{{ item.description }}</el-tag>
            </el-col>
          </el-row>

        </el-tab-pane>

      </el-tabs>
    </el-form-item>

    <el-form-item class="centered-item">
      <el-button type="primary" @click="beforeSubmit">保存</el-button>
      <el-button type="info" @click="getDataList">重置</el-button>
    </el-form-item>

  </el-form>

</template>

<script>

export default {
  data() {
    return {
      categoryDataForm: [],
      currentCategory: ''
    }
  },
  async created() {
    await this.getDataList()
    this.currentCategory = this.categoryDataForm[0] ? this.categoryDataForm[0].id : "1"
  },
  methods: {
    ipClick(item) {
      item.select = !item.select
    },
    async getDataList() {
      // 获取所有类目
      await this.$http({
        url: this.$http.adornUrl(`/admin/virtualIp/allCategory`),
        method: 'get'
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.categoryDataForm = data.result
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    beforeSubmit() {
      this.$confirm('IP信息对于客户端影响大，当前配置是否已经确认无误？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataFormSubmit();
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$http({
        url: this.$http.adornUrl('/admin/virtualIp/updateCategory'),
        method: 'post',
        data: this.categoryDataForm
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.visible = false
            }
          })
          this.getDataList()
        } else {
          this.$message.error(data.msg)
        }
      })
    }
  }
}
</script>

<style>

.centered-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>